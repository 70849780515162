export const initialNotes = {
  data: [
    /* {
      id: "0",
      content: "this is my note 1",
      dateCreated: 1611595994352,
      dateModified: 1611595994352,
    },
    {
      id: "1",
      content: "this is my note 2",
      dateCreated: 1611595994352,
      dateModified: 1611595994352,
    },
    {
      id: "2",
      content: "this is my note 3",
      dateCreated: 1611595994352,
      dateModified: 1611595994352,
    }, */
  ],
  activeNoteId: '1',
  saved: true
};
