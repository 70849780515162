import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface ImportFirstLoadState {
  importFirstLoad: boolean;
}

const initialState: ImportFirstLoadState = {
  importFirstLoad: true
};

const slice = createSlice({
  name: 'importFirstLoad',
  initialState,
  reducers: {
    updateImportFirstLoad(state: ImportFirstLoadState, action: PayloadAction<boolean>): void {
      state.importFirstLoad = action.payload;
    }
  }
});

export const { updateImportFirstLoad } = slice.actions;

export default slice.reducer;
