import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface FilterIndexState {
  filterIndex: number;
}

const initialState: FilterIndexState = {
  filterIndex: 0
};

export const filterIndexSlice = createSlice({
  name: 'filterIndex',
  initialState,
  reducers: {
    setFilterIndex: (state, action: PayloadAction<number>) => {
      state.filterIndex = action.payload;
    }
  }
});

export const { setFilterIndex } = filterIndexSlice.actions;

export default filterIndexSlice.reducer;
