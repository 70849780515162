import setupReducer from 'redux/slices/setupReducer';
import setupApiReducer from 'redux/slices/setupApiReducer';
import importRecordReducer from 'redux/slices/importRecordReducer';
import importApiReducer from 'redux/slices/importApiReducer';
import importFirstLoad from 'redux/slices/importFirstLoad';
import ledgerFileReducer from 'redux/slices/ledgerFileReducer';
import ledgerFileConfigReducer from 'redux/slices/ledgerFileConfigReducer';
import isSetupLoading from 'redux/slices/isSetupLoading';
import isRecordLoading from 'redux/slices/isRecordLoading';
import isConfigLoading from 'redux/slices/isConfigLoading';
import isFilesLoading from 'redux/slices/isFilesLoading';
import errorReducer from 'redux/slices/errorReducer';
import currentRecords from 'redux/slices/currentRecords';
import userReducer from 'redux/slices/userReducer';
import appConfig from 'redux/slices/appConfig';
import userConfig from 'redux/slices/userConfig';
import notesReducer from 'redux/slices/notesReducer';
import apiKeyReducer from 'redux/slices/apiReducer';
import tempFilters from 'redux/slices/tempFilters';
import activityTime from 'redux/slices/activityTime';
import importOpenReducer from 'redux/slices/importOpenReducer';
import showBilling from 'redux/slices/showBilling';
import adminLoading from 'redux/slices/adminLoading';
import adminAlert from 'redux/slices/adminAlert';
import initData from 'redux/slices/initData';
import collaborators from 'redux/slices/collaborators';
import clients from 'redux/slices/clients';
import activeFileEditable from 'redux/slices/activeFileEditable';
import filterIndex from 'redux/slices/filterIndex';

import { combineReducers } from 'redux';
import metaFilesReducer from 'redux/slices/metaFilesReducer';
import counter from 'redux/slices/counter';
import copyRecord from 'redux/slices/copyRecord';
import copyNewRecordModal from 'redux/slices/copyNewRecordModal';
import AccountSetup from 'redux/slices/accountSetup';
import ClassSetup from 'redux/slices/classSetup';
import DescriptionSetup from 'redux/slices/descriptionSetup';
import AuxiliarReducer from 'redux/slices/auxiliarReducer';

const appReducer = combineReducers({
  AccountSetup,
  ClassSetup,
  DescriptionSetup,
  activeFileEditable,
  activityTime,
  adminAlert,
  adminLoading,
  appConfig,
  clients,
  collaborators,
  copyNewRecordModal,
  copyRecord,
  counter,
  currentRecords,
  error: errorReducer,
  filterIndex,
  importFirstLoad,
  importOpenReducer,
  importApiReducer,
  importRecordReducer,
  initData,
  isConfigLoading,
  isFilesLoading,
  isRecordLoading,
  isSetupLoading,
  ledgerFileConfigReducer: ledgerFileConfigReducer,
  ledgerFileReducer: ledgerFileReducer,
  metaFiles: metaFilesReducer,
  notesReducer,
  apiKeyReducer,
  setupDataReducer: setupReducer,
  setupApiReducer,
  showBilling,
  tempFilters,
  user: userReducer,
  userConfig,
  auxiliar: AuxiliarReducer,
});

export type AppReducer = ReturnType<typeof appReducer>;

const rootReducer = (state: AppReducer | undefined, action: any) => {
  if (action.type === 'RESET_APP') {
    state = undefined;
  }
  return appReducer(state, action);
};

export const resetAppAction = () => (dispatch: any) => {
  dispatch({ type: 'RESET_APP' });
};

export type RootReducer = ReturnType<typeof rootReducer>;
export default rootReducer;
