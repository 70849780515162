export interface IAppConfigState {
  themeColors: IThemeColors[];
}

export interface IThemeColors {
  id: string;
  primary: string;
  secondary: string;
  primaryAccent: string;
  secondaryAccent: string;
  logoAccent: string;
}

export const initialAppConfig: IAppConfigState = {
  themeColors: [
    {
      id: '0',
      primary: '#343A40',
      secondary: '#414950',
      primaryAccent: 'dark',
      secondaryAccent: 'dark',
      logoAccent: 'original'
    },
    {
      id: '1',
      primary: '#264653',
      secondary: '#2a9d8f',
      primaryAccent: 'dark',
      secondaryAccent: 'light',
      logoAccent: 'white'
    },
    {
      id: '2',
      primary: '#03045e',
      secondary: '#023e8a',
      primaryAccent: 'dark',
      secondaryAccent: 'dark',
      logoAccent: 'original'
    },
    {
      id: '3',
      primary: '#797d62',
      secondary: '#9b9b7a',
      primaryAccent: 'light',
      secondaryAccent: 'dark',
      logoAccent: 'original'
    },
    {
      id: '4',
      primary: '#007f5f',
      secondary: '#2b9348',
      primaryAccent: 'dark',
      secondaryAccent: 'dark',
      logoAccent: 'white'
    },
    {
      id: '5',
      primary: '#0a2463',
      secondary: '#3e92cc',
      primaryAccent: 'dark',
      secondaryAccent: 'dark',
      logoAccent: 'white'
    }
  ]
};
