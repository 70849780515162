export const initialUserConfig = {
  selectedThemeId: '0',
  activeFileId: '',
  workspace: {
    workspaceId: 'own',
    workspaceEmail: '',
    clientSubscriptionType: 'expanded',
    clientSubscriptionStatus: 'active'
  }
};
