import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { setupData } from '../../data/setupData';
import C from '../constants';
// import { ISetupData } from '../../data/setupData.interface';

const initialState = setupData;

export const setupFileSlice = createSlice({
  name: 'setupApiReducer',
  initialState,
  reducers: {
    updateApiSetupReducer: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
    resetStepImportCsv: (state: any) => {
      state.importProps.activeStep = 0;
    }
  }
});

export const { updateApiSetupReducer, resetStepImportCsv } = setupFileSlice.actions;

export default setupFileSlice.reducer;
