import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface Account {
  id: string | number;
  name: string;
  openingBalance: number;
}

interface AccountSetupState {
  selectedAccounts: Account[];
  dialogOpen: boolean;
  dialogType: string;
  auxiliarAccountSetup: Account[];
  recordsPerPage?: number;
  loginPassword?: string;
}

const initialState: AccountSetupState = {
  selectedAccounts: [],
  dialogOpen: false,
  dialogType: 'edit',
  auxiliarAccountSetup: [],
  recordsPerPage: 10,
  loginPassword: 'password'
};

const slice = createSlice({
  name: 'accountSetup',
  initialState,
  reducers: {
    setDialogOpen(state: AccountSetupState, action: PayloadAction<any>): void {
      state.dialogOpen = action.payload;
    },
    setDialogType(state: AccountSetupState, action: PayloadAction<any>): void {
      state.dialogType = action.payload;
    },
    setSelectedAccounts(state: AccountSetupState, action: PayloadAction<any>): void {
      state.selectedAccounts = action.payload;
    },
    addSelectedAccount(state: AccountSetupState, action: PayloadAction<any>): void {
      state.selectedAccounts.push(action.payload);
    },
    removeSelectedAccount(state: AccountSetupState, action: PayloadAction<any>): void {
      state.selectedAccounts = state.selectedAccounts.filter(
        (account) => account.id !== action.payload.id
      );
    },
    clearSelectedAccounts(state: AccountSetupState): void {
      state.selectedAccounts = [];
    },
    setAuxiliarAccountSetup(state: AccountSetupState, action: PayloadAction<any>): void {
      state.auxiliarAccountSetup = action.payload;
    },
    setRecordsPage(state: AccountSetupState, action: PayloadAction<number>): void {
      state.recordsPerPage = action.payload;
    },
    setLoginMethod(state: AccountSetupState, action: PayloadAction<string>): void {
      state.loginPassword = action.payload;
    },
  }
});

export const {
  setDialogOpen,
  setDialogType,
  setSelectedAccounts,
  addSelectedAccount,
  removeSelectedAccount,
  clearSelectedAccounts,
  setAuxiliarAccountSetup,
  setRecordsPage,
  setLoginMethod
} = slice.actions;

export default slice.reducer;
