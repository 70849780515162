import { initialNotes } from 'data/initialNotes';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface NotesReducerState {
  data: any[];
  activeNoteId: string;
  saved: boolean;
}

const initialState: NotesReducerState = initialNotes;

export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    addNotes: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
    addNoteReducer: (state, action: PayloadAction<any>) => {
      state.data.push(action.payload);
      // state.activeNoteId = action.payload.id;
    },
    deleteNote: (state, action: PayloadAction<string>) => {
      let filteredNotes = state.data.filter((note) => {
        if (note.id !== action.payload) {
          return note;
        } else return null;
      });
      state.data = filteredNotes;
      if (filteredNotes.length > 0) {
        state.activeNoteId = filteredNotes[0].id;
      }
    },
    updateActiveNoteIdReducer: (state, action: PayloadAction<string>) => {
      state.activeNoteId = action.payload;
    },
    updateNote: (state, action: PayloadAction<any>) => {
      for (var i = 0; i < state.data.length; i++) {
        if (state.data[i].id === action.payload.id) {
          state.data[i] = action.payload;
        }
      }
      state.saved = false;
    },
    updateNoteStatus: (state, action: PayloadAction<boolean>) => {
      state.saved = action.payload;
    }
  }
});

export const {
  addNotes,
  addNoteReducer,
  deleteNote,
  updateActiveNoteIdReducer,
  updateNote,
  updateNoteStatus
} = notesSlice.actions;

export default notesSlice.reducer;
