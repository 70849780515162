import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

interface CounterState {
  value: number;
}

const initialState: CounterState = {
  value: 0
};

const slice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment(state: CounterState): void {
      state.value += 1;
      console.log(state.value);
    },
    decrement(state: CounterState): void {
      state.value -= 1;
    },
    reset(state: CounterState): void {
      state.value = 0;
    },
    set(state: CounterState, action: PayloadAction<number>): void {
      state.value = action.payload;
    }
  }
});
export const { increment, decrement, reset, set } = slice.actions;

export default slice.reducer;
