export const setupData = {
  dateFormat: 'MM/dd/yyyy',
  businessName: 'Proledger Private Limited',
  financialMonth: 0,
  expenseCategories: [
    {
      id: '0',
      name: 'General',
      categoryType: 'main',
      appearOnStatement: true,
      splitFigures: { personal: 50, business: 50 },
      defaultClass: 'General Business',
      defaultAccount: 'Cash',
      defaultTaxes: [true, false],
      openingBalance: 0,
      collapsed: false,
      subCategories: []
    },
    {
      id: '1',
      name: 'Property',
      categoryType: 'main',
      appearOnStatement: true,
      splitFigures: { personal: 50, business: 50 },
      defaultClass: 'General Business',
      defaultAccount: 'Cash',
      defaultTaxes: [true, false],
      openingBalance: 0,
      collapsed: false,
      subCategories: [
        {
          id: '1_0',
          name: 'Mortgage/Loan Payments',
          categoryType: 'sub',
          mainCategoryId: '1',
          appearOnStatement: false,
          splitFigures: { personal: 100, business: 0 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '1_1',
          name: 'Property Taxes',
          categoryType: 'sub',
          mainCategoryId: '1',
          appearOnStatement: true,
          splitFigures: { personal: 90, business: 10 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        }
      ]
    },
    {
      id: '2',
      name: 'Office',
      categoryType: 'main',
      appearOnStatement: true,
      splitFigures: { personal: 50, business: 50 },
      defaultClass: 'General Business',
      defaultAccount: 'Cash',
      defaultTaxes: [true, false],
      openingBalance: 0,
      collapsed: false,
      subCategories: [
        {
          id: '2_0',
          name: 'Advertising & Promotion',
          categoryType: 'sub',
          mainCategoryId: '2',
          appearOnStatement: true,
          splitFigures: { personal: 50, business: 50 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '2_1',
          name: 'Management and Admin',
          categoryType: 'sub',
          mainCategoryId: '2',
          appearOnStatement: true,
          splitFigures: { personal: 50, business: 50 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '2_2',
          name: 'Office Expenses',
          categoryType: 'sub',
          mainCategoryId: '2',
          appearOnStatement: true,
          splitFigures: { personal: 50, business: 50 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '2_3',
          name: 'Professional Fees',
          categoryType: 'sub',
          mainCategoryId: '2',
          appearOnStatement: true,
          splitFigures: { personal: 50, business: 50 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '2_4',
          name: 'Stationary and Supplies',
          categoryType: 'sub',
          mainCategoryId: '2',
          appearOnStatement: true,
          splitFigures: { personal: 10, business: 90 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '2_5',
          name: 'Telephone',
          categoryType: 'sub',
          mainCategoryId: '2',
          appearOnStatement: true,
          splitFigures: { personal: 20, business: 80 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        }
      ]
    },
    {
      id: '3',
      name: 'Automotive',
      categoryType: 'main',
      appearOnStatement: true,
      splitFigures: { personal: 50, business: 50 },
      defaultClass: 'General Business',
      defaultAccount: 'Cash',
      defaultTaxes: [true, false],
      openingBalance: 0,
      collapsed: false,
      subCategories: [
        {
          id: '3_0',
          name: 'Auto Lease/Loan Payment',
          categoryType: 'sub',
          mainCategoryId: '3',
          appearOnStatement: true,
          splitFigures: { personal: 20, business: 80 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '3_1',
          name: 'Automotive Gas & Oil',
          categoryType: 'sub',
          mainCategoryId: '3',
          appearOnStatement: true,
          splitFigures: { personal: 20, business: 80 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '3_2',
          name: 'Automotive Insurance & License',
          categoryType: 'sub',
          mainCategoryId: '3',
          appearOnStatement: true,
          splitFigures: { personal: 20, business: 80 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '3_3',
          name: 'Automotive Repairs',
          categoryType: 'sub',
          mainCategoryId: '3',
          appearOnStatement: true,
          splitFigures: { personal: 20, business: 80 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '3_4',
          name: 'Licence and Permits',
          categoryType: 'sub',
          mainCategoryId: '3',
          appearOnStatement: true,
          splitFigures: { personal: 50, business: 50 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '3_5',
          name: 'Maintenance and Repairs',
          categoryType: 'sub',
          mainCategoryId: '3',
          appearOnStatement: true,
          splitFigures: { personal: 90, business: 10 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '3_6',
          name: 'Parking',
          categoryType: 'sub',
          mainCategoryId: '3',
          appearOnStatement: true,
          splitFigures: { personal: 20, business: 80 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        }
      ]
    },
    {
      id: '4',
      name: 'Personal',
      categoryType: 'main',
      appearOnStatement: true,
      splitFigures: { personal: 50, business: 50 },
      defaultClass: 'General Business',
      defaultAccount: 'Cash',
      defaultTaxes: [true, false],
      openingBalance: 0,
      collapsed: false,
      subCategories: [
        {
          id: '4_0',
          name: 'Cable TV',
          categoryType: 'sub',
          mainCategoryId: '4',
          appearOnStatement: false,
          splitFigures: { personal: 100, business: 0 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '4_1',
          name: 'Cell Phone',
          categoryType: 'sub',
          mainCategoryId: '4',
          appearOnStatement: true,
          splitFigures: { personal: 10, business: 90 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '4_2',
          name: 'Clothing',
          categoryType: 'sub',
          mainCategoryId: '4',
          appearOnStatement: false,
          splitFigures: { personal: 100, business: 0 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '4_3',
          name: 'Donations',
          categoryType: 'sub',
          mainCategoryId: '4',
          appearOnStatement: false,
          splitFigures: { personal: 100, business: 0 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '4_4',
          name: 'Electricity',
          categoryType: 'sub',
          mainCategoryId: '4',
          appearOnStatement: true,
          splitFigures: { personal: 90, business: 10 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '4_5',
          name: 'Entertainment',
          categoryType: 'sub',
          mainCategoryId: '4',
          appearOnStatement: true,
          splitFigures: { personal: 50, business: 50 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '4_6',
          name: 'Gifts',
          categoryType: 'sub',
          mainCategoryId: '4',
          appearOnStatement: false,
          splitFigures: { personal: 100, business: 0 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '4_7',
          name: 'Groceries',
          categoryType: 'sub',
          mainCategoryId: '4',
          appearOnStatement: false,
          splitFigures: { personal: 100, business: 0 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '4_8',
          name: 'Health & Beauty',
          categoryType: 'sub',
          mainCategoryId: '4',
          appearOnStatement: false,
          splitFigures: { personal: 100, business: 0 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '4_9',
          name: 'Heat',
          categoryType: 'sub',
          mainCategoryId: '4',
          appearOnStatement: true,
          splitFigures: { personal: 90, business: 10 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '4_10',
          name: 'Household Supplies',
          categoryType: 'sub',
          mainCategoryId: '4',
          appearOnStatement: true,
          splitFigures: { personal: 90, business: 10 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '4_11',
          name: 'Pet Supplies',
          categoryType: 'sub',
          mainCategoryId: '4',
          appearOnStatement: false,
          splitFigures: { personal: 100, business: 0 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '4_12',
          name: 'Travel',
          categoryType: 'sub',
          mainCategoryId: '4',
          appearOnStatement: true,
          splitFigures: { personal: 50, business: 50 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '4_13',
          name: 'Water',
          categoryType: 'sub',
          mainCategoryId: '4',
          appearOnStatement: true,
          splitFigures: { personal: 90, business: 10 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        }
      ]
    },
    {
      id: '5',
      name: 'Bank',
      categoryType: 'main',
      appearOnStatement: true,
      splitFigures: { personal: 50, business: 50 },
      defaultClass: 'General Business',
      defaultAccount: 'Cash',
      defaultTaxes: [true, false],
      openingBalance: 0,
      collapsed: false,
      subCategories: [
        {
          id: '5_0',
          name: 'Accounting & Legal Fees',
          categoryType: 'sub',
          mainCategoryId: '5',
          appearOnStatement: true,
          splitFigures: { personal: 50, business: 50 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '5_1',
          name: 'Bank Charges',
          categoryType: 'sub',
          mainCategoryId: '5',
          appearOnStatement: true,
          splitFigures: { personal: 50, business: 50 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '5_2',
          name: 'Insurance',
          categoryType: 'sub',
          mainCategoryId: '5',
          appearOnStatement: true,
          splitFigures: { personal: 90, business: 10 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '5_3',
          name: 'Interest',
          categoryType: 'sub',
          mainCategoryId: '5',
          appearOnStatement: true,
          splitFigures: { personal: 90, business: 10 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: '5_4',
          name: 'Internet',
          categoryType: 'sub',
          mainCategoryId: '5',
          appearOnStatement: true,
          splitFigures: { personal: 50, business: 50 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        }
      ]
    },
    {
      id: '6',
      name: 'Others',
      categoryType: 'main',
      appearOnStatement: true,
      splitFigures: { personal: 50, business: 50 },
      defaultClass: 'General Business',
      defaultAccount: 'Cash',
      defaultTaxes: [true, false],
      openingBalance: 0,
      collapsed: false,
      subCategories: [
        {
          id: '6_0',
          name: 'Other',
          categoryType: 'sub',
          mainCategoryId: '6',
          appearOnStatement: true,
          splitFigures: { personal: 50, business: 50 },
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        }
      ]
    }
  ],
  accountCollection: [
    { id: '0', name: 'Cash', openingBalance: 0 },
    { id: '1', name: 'Chequing', openingBalance: 0 },
    { id: '2', name: 'Savings', openingBalance: 0 },
    { id: '3', name: 'Visa', openingBalance: 0 },
    { id: '4', name: 'Mastercard', openingBalance: 0 },
    { id: '5', name: 'AMEX', openingBalance: 0 },
    { id: '6', name: 'Payable', openingBalance: 0 },
    { id: '7', name: 'Receivable', openingBalance: 0 }
  ],
  classCollection: [
    { id: '0', name: 'General Business' },
    { id: '1', name: 'Split' },
    { id: '2', name: 'Personal' },
    { id: '3', name: 'Project 1' },
    { id: '4', name: 'Project 2' }
  ],
  taxCollection: [
    {
      id: '0',
      name: 'GST/HST',
      rate: '5'
    },
    {
      id: '1',
      name: 'PST',
      rate: '7'
    }
  ],
  costOfGoodsCategories: [
    {
      id: 'c_0',
      name: 'General Costs',
      categoryType: 'main',
      appearOnStatement: true,
      defaultClass: 'General Business',
      defaultAccount: 'Cash',
      defaultTaxes: [true, false],
      openingBalance: 0,
      collapsed: false,
      subCategories: [
        {
          id: 'c_0_0',
          name: 'Distribution cost',
          categoryType: 'sub',
          mainCategoryId: 'c_0',
          appearOnStatement: true,
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: 'c_0_1',
          name: 'Sales force',
          categoryType: 'sub',
          mainCategoryId: 'c_0',
          appearOnStatement: true,
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        }
      ]
    },
    {
      id: 'c_1',
      name: 'Category 1',
      categoryType: 'main',
      appearOnStatement: true,
      defaultClass: 'General Business',
      defaultAccount: 'Cash',
      defaultTaxes: [true, false],
      openingBalance: 0,
      collapsed: false,
      subCategories: [
        {
          id: 'c_1_0',
          name: 'Storage',
          categoryType: 'sub',
          mainCategoryId: 'c_1',
          appearOnStatement: true,
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        }
      ]
    }
  ],
  incomeCategories: [
    {
      id: 'i_0',
      name: 'General Income',
      categoryType: 'main',
      appearOnStatement: true,
      defaultClass: 'General Business',
      defaultAccount: 'Cash',
      defaultTaxes: [true, false],
      openingBalance: 0,
      collapsed: false,
      subCategories: [
        {
          id: 'i_0_0',
          name: 'Marketing Income',
          categoryType: 'sub',
          mainCategoryId: 'i_0',
          appearOnStatement: true,
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        },
        {
          id: 'i_0_1',
          name: 'Commissions',
          categoryType: 'sub',
          mainCategoryId: 'i_0',
          appearOnStatement: true,
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        }
      ]
    },
    {
      id: 'i_1',
      name: 'Sales',
      categoryType: 'main',
      appearOnStatement: true,
      defaultClass: 'General Business',
      defaultAccount: 'Cash',
      defaultTaxes: [true, false],
      openingBalance: 0,
      collapsed: false,
      subCategories: []
    },
    {
      id: 'i_2',
      name: 'Real Estate',
      categoryType: 'main',
      appearOnStatement: true,
      defaultClass: 'General Business',
      defaultAccount: 'Cash',
      defaultTaxes: [true, false],
      openingBalance: 0,
      collapsed: false,
      subCategories: [
        {
          id: 'i_2_0',
          name: 'Rent',
          categoryType: 'sub',
          mainCategoryId: 'i_2',
          appearOnStatement: true,
          defaultClass: 'General Business',
          defaultAccount: 'Cash',
          defaultTaxes: [true, false],
          openingBalance: 0
        }
      ]
    }
  ],
  transferCategories: [
    {
      id: 't_0',
      name: 'Transfer In(+)',
      categoryType: 'main',
      appearOnStatement: true,
      splitFigures: { personal: 50, business: 50 },
      defaultClass: 'General Business',
      defaultAccount: 'Cash',
      defaultTaxes: [false, false],
      openingBalance: 0,
      collapsed: false,
      subCategories: []
    },
    {
      id: 't_1',
      name: 'Transfer Out(-)',
      categoryType: 'main',
      appearOnStatement: true,
      splitFigures: { personal: 50, business: 50 },
      defaultClass: 'General Business',
      defaultAccount: 'Cash',
      defaultTaxes: [false, false],
      openingBalance: 0,
      collapsed: false,
      subCategories: []
    }
  ],
  descriptionCollection: [
    {
      id: '0',
      name: 'Annual car insurance bill',
      categoryTags: [{ id: '0', type: 'Expense', category: 'Auto' }]
    },
    { id: '1', name: 'Annual house insurance bill', categoryTags: [] },
    { id: '2', name: 'Esso Fuel - gas for car', categoryTags: [] },
    { id: '3', name: 'Income from work', categoryTags: [] },
    { id: '4', name: 'Monthly Cable bill for the house', categoryTags: [] },
    {
      id: '5',
      name: 'Monthly cell phone bill for xxx-xxx-xxxx',
      categoryTags: []
    },
    { id: '6', name: 'Monthly electric bill for the house', categoryTags: [] },
    { id: '7', name: 'Monthly heating bill for the home', categoryTags: [] },
    {
      id: '8',
      name: 'Monthly home phone bill for xxx-xxx-xxxx',
      categoryTags: []
    },
    { id: '9', name: 'Monthly internet bill', categoryTags: [] },
    { id: '10', name: 'Monthly mortgage payment', categoryTags: [] },
    { id: '11', name: 'Parking Garage for car at work', categoryTags: [] },
    { id: '12', name: 'Shell fuel - gas for car', categoryTags: [] }
  ],
  filters: {
    accountValue: ['All'],
    classValue: ['All'],
    categoryValue: ['All'],
    dateComparatorValue: 'All',
    transactionType: 'All',
    rec: 'All',
    selectedDate1: new Date(),
    selectedDate2: new Date(),
    searchByValue: 'Description',
    searchByComparatorsValue: 'Equals',
    searchValue: '',
    showBookmarks: false
  },
  fileFilters: [
    {
      user: '0',
      filters: {
        accountValue: ['All'],
        classValue: ['All'],
        categoryValue: ['All'],
        dateComparatorValue: 'All',
        transactionType: 'All',
        rec: 'All',
        selectedDate1: new Date(),
        selectedDate2: new Date(),
        searchByValue: 'Description',
        searchByComparatorsValue: 'Equals',
        searchValue: '',
        showBookmarks: false
      }
    }
  ],
  criteriaStatements: [],
  criteriaInvoiceStatements: [],
  importedFile: {
    row1IsHeading: true,
    activeStep: 0,
    activeStepInvo: 0,
    defaultDateFormat: '',
    defaultAccount: '',
    dataColumns: [],
    finalizedRecords: [],
    defaultHeadings: ['Date', 'Class', 'Category', 'Description', 'Total'],
    defaultHeadingsInvoice: ['Date', 'Class', 'Category', 'Description', 'Total']
  }
};
