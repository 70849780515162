// import { ledgerFile } from "data/recordsData";
import C from '../constants';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface LedgerFileConfig {
  active: boolean;
  activeDashboard: string;
  chartType: string;
  color: string;
  dashboardViewby: string;
  dateCreated: string;
  date_created: string;
  description: string;
  expense: any[];
  favourite: boolean;
  file_id: string;
  id: string;
  income: any[];
  lastActivity: string;
  lastUpdated: string;
  ledgerType: string;
  name: string;
  netIncome: any[];
  previouseDays: number;
  taxType: string;
}

const initialStateLedgerFileConfig: LedgerFileConfig | null = null;

const ledgerFileConfigSlice = createSlice({
  name: 'ledgerFileConfigReducer',
  initialState: initialStateLedgerFileConfig,
  reducers: {
    updateActiveMetaFile: (state, action: PayloadAction<any>) => {
      const copyPayload = { ...action.payload };
      if (!copyPayload.hasOwnProperty('activeDashboard')) {
        copyPayload.activeDashboard = 'Records';
      }
      if (!copyPayload.hasOwnProperty('dashboardViewby')) {
        copyPayload.dashboardViewby = 'Deal date';
      }
      return copyPayload;
    }
  }
});

export const { updateActiveMetaFile } = ledgerFileConfigSlice.actions;

export default ledgerFileConfigSlice.reducer;
