import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface IsRecordLoadingState {
  isRecordLoading: boolean;
}

const initialState: IsRecordLoadingState = {
  isRecordLoading: false
};

const slice = createSlice({
  name: 'isRecordLoading',
  initialState,
  reducers: {
    setRecordLoading(state: IsRecordLoadingState, action: PayloadAction<boolean>): void {
      state.isRecordLoading = action.payload;
    }
  }
});

export const { setRecordLoading } = slice.actions;

export default slice.reducer;
