import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  loading: boolean;
  data: any;
}

const initialState: UserState = { loading: true, data: null };

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state: UserState, action: PayloadAction<any>): any {
      // return {
      //   ...state,
      //   loading: false,
      //   data: action.payload
      // };
      state.loading = false;
      state.data = action.payload;
    },
    setUserObject(state: UserState, action: PayloadAction<any>): any {
      state.data = action.payload
    },
    setUserLoading(state: UserState): void {
      state.loading = true;
    },
    removeUser(state: UserState): void {
      state.loading = false;
      state.data = null;
    }
  }
});

export const { setUser, setUserLoading, removeUser, setUserObject } = slice.actions;

export default slice.reducer;

// const user = (state = { loading: true, data: null }, action: any) => {
//   switch (action.type) {
//     case C.SET_USER:
//       return { loading: false, data: action.payload };
//     case C.SET_USER_LOADING:
//       return { ...state, loading: true };
//     case C.REMOVE_USER:
//       return { loading: false, data: null };
//     default:
//       return state;
//   }
// };
// export default user;
