import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface AdminAlertState {
  show: boolean;
  message: string;
  title: string;
}

const initialState: AdminAlertState = {
  show: false,
  message: '',
  title: ''
};

const slice = createSlice({
  name: 'adminAlert',
  initialState,
  reducers: {
    showAdminAlert(state: AdminAlertState, action: PayloadAction<AdminAlertState>): void {
      state.show = action.payload.show;
      state.message = action.payload.message;
      state.title = action.payload.title;
    }
  }
});
export const { showAdminAlert } = slice.actions;

export default slice.reducer;
