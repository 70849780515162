import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface IsSetupLoadingState {
  isSetupLoading: boolean;
}

const initialState: IsSetupLoadingState = {
  isSetupLoading: false
};

const slice = createSlice({
  name: 'isSetupLoading',
  initialState,
  reducers: {
    setSetupLoading(state: IsSetupLoadingState, action: PayloadAction<boolean>): void {
      state.isSetupLoading = action.payload;
    }
  }
});

export const { setSetupLoading } = slice.actions;

export default slice.reducer;
