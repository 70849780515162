import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface ActivityTimeState {
  value: number;
}

const initialState: ActivityTimeState = {
  value: new Date().getTime()
};

const slice = createSlice({
  name: 'activityTime',
  initialState,
  reducers: {
    updateActivityTime(state: ActivityTimeState, action: PayloadAction<number>): void {
      state.value = action.payload;
    }
  }
});
export const { updateActivityTime } = slice.actions;

export default slice.reducer;
