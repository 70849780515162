import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface categoryTag {
  category: string;
  id: string | number;
  type: string;
}

interface Description {
  id: string | number;
  name: string;
  categoryTags: categoryTag[];
}

interface DescriptionSetupState {
  selectedDescription: Description[];
}

const initialState: DescriptionSetupState = {
  selectedDescription: []
};

const slice = createSlice({
  name: 'descriptionSetup',
  initialState,
  reducers: {
    setSelectedDescriptions(state: DescriptionSetupState, action: PayloadAction<any>): void {
      state.selectedDescription = action.payload;
    },
    addSelectedDescription(state: DescriptionSetupState, action: PayloadAction<any>): void {
      state.selectedDescription.push(action.payload);
    },
    removeSelectedDescription(state: DescriptionSetupState, action: PayloadAction<any>): void {
      state.selectedDescription = state.selectedDescription.filter(
        (account) => account.id !== action.payload.id
      );
    },
    clearSelectedDescriptions(state: DescriptionSetupState): void {
      state.selectedDescription = [];
    }
  }
});

export const {
  setSelectedDescriptions,
  addSelectedDescription,
  removeSelectedDescription,
  clearSelectedDescriptions
} = slice.actions;

export default slice.reducer;
