import { initialAppConfig, IAppConfigState } from '../../data/initialAppConfig';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: IAppConfigState = {
  themeColors: initialAppConfig.themeColors
};

const slice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    updateAppConfig(state: IAppConfigState, action: PayloadAction<any>): void {
      state.themeColors = action.payload;
    }
  }
});

export const { updateAppConfig } = slice.actions;

export default slice.reducer;
