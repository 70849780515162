import firebase from 'firebase/compat/app';

// Add the Firebase services that you want to use
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import { firebaseConfig } from './config';
// firebase.analytics();
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
app.analytics();
export default app;
