import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { setupData } from '../../data/setupData';
// import { ISetupData } from '../../data/setupData.interface';

const initialState = setupData;

export const setupFileSlice = createSlice({
  name: 'setupReducer',
  initialState,
  reducers: {
    updateSetupReducer: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
    resetStepImportCsv: (state: any) => {
      state.importProps.activeStep = 0;
    },
    resetStepImportInvoice: (state: any) => {
      state.importProps.activeStepInvo = 0;
    }
  }
});

export const { updateSetupReducer, resetStepImportCsv, resetStepImportInvoice } = setupFileSlice.actions;

export default setupFileSlice.reducer;
