import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IRecordCopy } from 'interfaces/copyRecord';

interface CopyRecordState {
  record: IRecordCopy | null;
}

const initialState: CopyRecordState = {
  record: null
};

const slice = createSlice({
  name: 'copyRecord',
  initialState,
  reducers: {
    setRecord(state: CopyRecordState, action: PayloadAction<IRecordCopy>): void {
      state.record = action.payload;
    }
  }
});

export const { setRecord } = slice.actions;

export default slice.reducer;
