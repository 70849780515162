import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface ActiveFileEditableState {
  isActive: boolean;
}

const initialState: ActiveFileEditableState = {
  isActive: false
};

const slice = createSlice({
  name: 'activeFileEditable',
  initialState,
  reducers: {
    setActiveFilePermission(state: ActiveFileEditableState, action: PayloadAction<any>): void {
      state.isActive = action.payload;
    }
  }
});

export const { setActiveFilePermission } = slice.actions;

export default slice.reducer;
