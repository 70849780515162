import { createSlice } from '@reduxjs/toolkit';

interface CopyNewRecordModalState {
  isOpen: boolean;
}

const initialState: CopyNewRecordModalState = {
  isOpen: false
};

const slice = createSlice({
  name: 'copyNewRecordModal',
  initialState,
  reducers: {
    open(state: CopyNewRecordModalState): void {
      state.isOpen = true;
    },
    closeModal(state: CopyNewRecordModalState): void {
      state.isOpen = false;
    }
  }
});

export const { open, closeModal } = slice.actions;

export default slice.reducer;
