import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface MetaFileState {
  metaFiles: any[];
}

const initialState: MetaFileState = {
  metaFiles: []
};

export const metaFileSlice = createSlice({
  name: 'metaFilesReducer',
  initialState,
  reducers: {
    setFile: (state, action: PayloadAction<any[]>) => {
      state.metaFiles = action.payload;
    },
    updateActiveFile: (state, action: PayloadAction<any>) => {
      state.metaFiles = state.metaFiles.map((file) => {
        if (file.id === action.payload) {
          return { ...file, active: true };
        } else {
          return { ...file, active: false };
        }
      });
    },
    toggleFavourite: (state, action: PayloadAction<any>) => {
      state.metaFiles = state.metaFiles.map((file) => {
        if (file.id === action.payload.id) {
          return { ...file, favourite: !file.favourite };
        } else {
          return file;
        }
      });
    },
    updateFile: (state, action: PayloadAction<any>) => {
      state.metaFiles = state.metaFiles.map((file) => {
        if (file.id === action.payload.id) {
          return action.payload;
        } else {
          return file;
        }
      });
    },
    sortMetaFiles: (state) => {
      let s = [...state.metaFiles];
      state.metaFiles = s.sort((a, b) => +new Date(b.lastUpdated) - +new Date(a.lastUpdated));
    },
    deleteFile: (state, action: PayloadAction<any>) => {
      state.metaFiles = state.metaFiles.filter((file) => {
        if (file.id === action.payload) {
          return null;
        } else {
          return { file };
        }
      });
    },
    logFileLastUpdateReducer: (state, action: PayloadAction<any>) => {
      state.metaFiles = state.metaFiles.map((file) => {
        if (file.id === action.payload.id) {
          return action.payload;
        } else {
          return file;
        }
      });
    },
    updateNewFile: (state, action: PayloadAction<any>) => {
      state.metaFiles = [action.payload, ...state.metaFiles];
    }
  }
});

export const {
  setFile,
  updateActiveFile,
  toggleFavourite,
  updateFile,
  sortMetaFiles,
  deleteFile,
  logFileLastUpdateReducer,
  updateNewFile
} = metaFileSlice.actions;

export default metaFileSlice.reducer;
