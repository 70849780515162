import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IResponseGoogleLoginData } from 'types/googleLogin';
import _ from 'lodash';

const initialState = {};

const parseSubRecords = (data: IResponseGoogleLoginData) => {
  const copyData = _.cloneDeep(data);
  if (!copyData.fileData) return copyData;
  const records = copyData.fileData.records;

  const mappedRecords = records.map((record) => {
    record.subRecords = record.subRecords.map((subRecord) => {
      if (typeof subRecord.total === 'string') {
        subRecord.total = +subRecord.total;
      }
      return subRecord;
    });
    return record;
  });
  copyData.fileData.records = mappedRecords;
  return copyData;
};

export const initDataSlice = createSlice({
  name: 'initData',
  initialState,
  reducers: {
    setInitData: (state, action: PayloadAction<IResponseGoogleLoginData>) => {
      const data = action.payload;
      const parsedData = parseSubRecords(data);
      return parsedData;
    },
    updateClientsData: (state, action: PayloadAction<any>) => {
      return { ...state, clients: action.payload };
    }
  }
});

export const { setInitData, updateClientsData } = initDataSlice.actions;

export default initDataSlice.reducer;

// const initData = (state = {}, action: any) => {
//   switch (action.type) {
//     case 'UPDATE_INIT_DATA':
//       return action.payload;
//     case 'UPDATE_CLIENTS_DATA':
//       return { ...state, clients: action.payload };
//     default:
//       return state;
//   }
// };
// export default initData;
