import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface ImportRecordState {
  importRecord: ImportRecord[];
  importRecordTemp: ImportRecord[];
}

interface ImportRecord {
  id?: string;
}

const initialState: ImportRecordState = {
  importRecord: [],
  importRecordTemp: []
};

export const importApiSlice = createSlice({
  name: 'importApiSlice',
  initialState,
  reducers: {
    removeTempRecords: (state, action: PayloadAction<ImportRecord[]>) => {
      state.importRecord = action.payload;
    },
    loadTempRecords: (state, action: PayloadAction<ImportRecord[]>) => {
      state.importRecordTemp = action.payload;
    },
    addTempRecords: (state, action: PayloadAction<ImportRecord[]>) => {
      let records = [...state.importRecord];
      for (var i = 0; i < action.payload.length; i++) {
        records.push(action.payload[i]);
      }
      state.importRecord = records;
    },
    deleteTempRecords: (state, action: PayloadAction<ImportRecord[]>) => {
      state.importRecord = state.importRecord.filter((record: any) => {
        let included = false;
        for (let i = 0; i < action.payload.length; i++) {
          if (action.payload[i].id === record.id) {
            included = true;
          }
        }
        if (included) {
          return null;
        } else {
          return record;
        }
      });
    },
    updateTempRecords: (state, action: PayloadAction<ImportRecord[]>) => {
      state.importRecord = state.importRecord.map((record: any) => {
        let updatedRecord = record;
        for (let i = 0; i < action.payload.length; i++) {
          if (action.payload[i].id === record.id) {
            updatedRecord = action.payload[i];
            break;
          }
        }
        return updatedRecord;
      });
    }
  }
});

export const { loadTempRecords, addTempRecords, deleteTempRecords, updateTempRecords, removeTempRecords } =
importApiSlice.actions;

export default importApiSlice.reducer;

// const importRecordReducer = (state = [], action) => {
//   switch (action.type) {
//     case 'LOAD_TEMP_RECORDS':
//       return action.payload;
//     case 'ADD_TEMP_RECORDS':
//       let records = [...state];
//       for (var i = 0; i < action.payload.length; i++) {
//         records.push(action.payload[i]);
//       }
//       return records;
//     case 'DELETE_TEMP_RECORDS':
//       return state.filter((record) => {
//         let included = false;
//         for (let i = 0; i < action.payload.length; i++) {
//           if (action.payload[i].id === record.id) {
//             included = true;
//           }
//         }
//         if (included) {
//           return null;
//         } else {
//           return record;
//         }
//       });
//     case 'UPDATE_TEMP_RECORDS':
//       return state.map((record) => {
//         let updatedRecord = record;
//         for (let i = 0; i < action.payload.length; i++) {
//           if (action.payload[i].id === record.id) {
//             updatedRecord = action.payload[i];
//             break;
//           }
//         }
//         return updatedRecord;
//       });
//     default:
//       return state;
//   }
// };
// export default importRecordReducer;
