import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import C from '../constants';

interface IsConfigLoadingState {
  isConfigLoading: boolean;
}

const initialState: IsConfigLoadingState = {
  isConfigLoading: false
};

const slice = createSlice({
  name: 'isConfigLoading',
  initialState,
  reducers: {
    setConfigLoading(state: IsConfigLoadingState, action: PayloadAction<boolean>): void {
      state.isConfigLoading = action.payload;
    }
  }
});

export const { setConfigLoading } = slice.actions;

export default slice.reducer;
