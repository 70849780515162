import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface Class {
  id: string | number;
  name: string;
}

interface ClassSetupState {
  selectedClasses: Class[];
}

const initialState: ClassSetupState = {
  selectedClasses: []
};

const slice = createSlice({
  name: 'classSetup',
  initialState,
  reducers: {
    setSelectedClass(state: ClassSetupState, action: PayloadAction<any>): void {
      state.selectedClasses = action.payload;
    },
    addSelectedClass(state: ClassSetupState, action: PayloadAction<any>): void {
      state.selectedClasses.push(action.payload);
    },
    removeSelectedClass(state: ClassSetupState, action: PayloadAction<any>): void {
      state.selectedClasses = state.selectedClasses.filter(
        (account) => account.id !== action.payload.id
      );
    },
    clearSelectedClasss(state: ClassSetupState): void {
      state.selectedClasses = [];
    }
  }
});

export const { setSelectedClass, addSelectedClass, removeSelectedClass, clearSelectedClasss } =
  slice.actions;

export default slice.reducer;
