// @ts-nocheck
import React from 'react';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

//// store
import { useSelector } from 'react-redux';

export default function SplashScreen() {
  const [logo] = React.useState(require('assets/img/logo.png'));
  const [logo_small] = React.useState(require('assets/img/logo_small.png'));
  const [loader] = React.useState(require('assets/img/loader.gif'));
  const error = useSelector((state) => state.error.error);

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
      <img
        src={matchesXS || matchesSM ? logo_small : logo}
        alt="logo"
        style={{ width: matchesXS || matchesSM ? '250px' : '400px' }}
      />
      <Typography style={{ marginTop: '50px', fontSize: '20px' }}>
        {error.length > 0 ? error[0].message : 'Loading'}
      </Typography>
      <img src={loader} style={{ marginTop: '30px' }} alt="loader" />
    </div>
  );
}
