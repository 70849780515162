import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface AdminLoadingState {
  show: boolean;
}

const initialState: AdminLoadingState = {
  show: false
};

const slice = createSlice({
  name: 'adminLoading',
  initialState,
  reducers: {
    setAdminLoading(state: AdminLoadingState, action: PayloadAction<boolean>): void {
      state.show = action.payload;
    }
  }
});
export const { setAdminLoading } = slice.actions;

export default slice.reducer;
