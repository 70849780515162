// @ts-nocheck
//import { filters } from "data/filtersData";
import _ from 'lodash';

const mergeExpenseCategories = (setupData) => {
  let merged = [];
  for (var i = 0; i < setupData.expenseCategories.length; i++) {
    merged.push(setupData.expenseCategories[i]);
    for (var j = 0; j < setupData.expenseCategories[i].subCategories.length; j++) {
      merged.push(setupData.expenseCategories[i].subCategories[j]);
    }
  }
  return merged;
};

const mergeIncomeCategories = (setupData) => {
  let merged = [];
  for (var i = 0; i < setupData.incomeCategories.length; i++) {
    merged.push(setupData.incomeCategories[i]);
    for (var j = 0; j < setupData.incomeCategories[i].subCategories.length; j++) {
      merged.push(setupData.incomeCategories[i].subCategories[j]);
    }
  }
  return merged;
};

const mergeCostOfGoodsCategories = (setupData) => {
  let merged = [];
  for (var i = 0; i < setupData.costOfGoodsCategories.length; i++) {
    merged.push(setupData.costOfGoodsCategories[i]);
    for (var j = 0; j < setupData.costOfGoodsCategories[i].subCategories.length; j++) {
      merged.push(setupData.costOfGoodsCategories[i].subCategories[j]);
    }
  }
  return merged;
};

const mergeTransferCategories = (setupData) => {
  let merged = [];
  for (var i = 0; i < setupData.transferCategories.length; i++) {
    merged.push(setupData.transferCategories[i]);
    for (var j = 0; j < setupData.transferCategories[i].subCategories.length; j++) {
      merged.push(setupData.transferCategories[i].subCategories[j]);
    }
  }
  return merged;
};

export const mergeCategories = (tType, setupData) => {
  let mergedExpenses = mergeExpenseCategories(setupData);
  let mergedIncomes = mergeIncomeCategories(setupData);
  let mergedCostOfGoods = mergeCostOfGoodsCategories(setupData);
  let margedTransfer = mergeTransferCategories(setupData);
  let merged = [];
  if (tType === 'All') {
    merged = mergedExpenses.concat(mergedIncomes.concat(mergedCostOfGoods));
  } else if (tType === 'Expense') {
    merged = mergedExpenses;
  } else if (tType === 'Income') {
    merged = mergedIncomes;
  } else if (tType === 'Cost of Goods Sold') {
    merged = mergedCostOfGoods;
  } else if (tType === 'Transfer') {
    merged = margedTransfer;
  }
  return merged;
};

export const checkTransactionType = (category, setupData) => {
  // console.log('setupData.incomeCategories', setupData.incomeCategories)
  if (checkCategories(setupData.expenseCategories, category)) {
    return 'Expense';
  } else if (checkCategories(setupData.incomeCategories, category)) {
    return 'Income';
  } else if (checkCategories(setupData.costOfGoodsCategories, category)) {
    return 'Cost of Goods Sold';
  } else if (checkCategories(setupData.transferCategories, category)) {
    return 'Transfer';
  }
  return '';
};

const checkCategories = (tCategories, category) => {
  for (var i = 0; i < tCategories.length; i++) {
    if (tCategories[i].name === category) {
      return true;
    }
    for (var j = 0; j < tCategories[i].subCategories.length; j++) {
      if (tCategories[i].subCategories[j].name === category) {
        return true;
      }
    }
  }
  return false;
};

export const getNormalizedArray = (currentData, previousData, defaultData) => {
  let allSelected = true;
  for (var i = 0; i < defaultData.length; i++) {
    if (currentData.indexOf(defaultData[i].name) === -1) {
      allSelected = false;
    }
  }
  let currentAllAvailable = currentData.indexOf('All') > -1 ? true : false;
  let previousAllAvailable = previousData.indexOf('All') > -1 ? true : false;
  if (!allSelected) {
    if (currentAllAvailable && !previousAllAvailable) {
      let newAcList = ['All'];

      for (var j = 0; j < defaultData.length; j++) {
        newAcList.push(defaultData[j].name);
      }
      currentData = newAcList;
    } else if (currentAllAvailable && previousAllAvailable) {
      currentData.splice(currentData.indexOf('All'), 1);
    }
  } else {
    if (!currentAllAvailable && !previousAllAvailable) {
      currentData.push('All');
    } else if (!currentAllAvailable && previousAllAvailable) {
      currentData = [];
    }
  }

  return currentData;
};

//////////////FILTERS
const findDuplicateRecords = (records) => {
  const recordMap = {};
  const allRecords = [];

  records.forEach((record) => {
    const key = `${record.date}-${record.total}`;

    if (!recordMap[key]) {
      // Si esta es la primera vez que vemos esta combinación, almacenamos el registro
      recordMap[key] = [record];
    } else {
      // Si ya hemos visto esta combinación, agregamos el registro actual a la lista
      recordMap[key].push(record);
    }

    // Almacenamos todos los registros, ya sean duplicados o no
    allRecords.push(record);
  });

  return allRecords.filter((record) => recordMap[`${record.date}-${record.total}`].length > 1);
};

export const filterRecords = (array, filters, setupData) => {
  if (filters.searchByValue === 'Duplicates') {
    if (filters?.duplicated) {
      array = array.filter(
        (objeto) => !filters?.duplicated.some((eliminar) => eliminar.id === objeto.id)
      );
    }
    const duplicatedItems = findDuplicateRecords(array);
    array = cloneObject(duplicatedItems);
  } else {
    array = cloneObject(array);
  }
  // array = cloneObject(array);

  return array.filter((record) => {
    if (
      checkForBookmarks(record, setupData, filters) &&
      ledgerPropFilter(record, filters) &&
      dateFilter(record, filters) &&
      filterSearchForMainRecord(record, filters) &&
      searchAmountFilter(record, filters, setupData)
    ) {
      return record;
    } else {
      return null;
    }
  });
};

const checkForBookmarks = (record, setupData, filters) => {
  if ((filters.showBookmarks && record.bookmark) || !filters.showBookmarks) {
    return true;
  } else {
    return false;
  }
};

const filterSearchForMainRecord = (record, filters) => {
  for (var i = 0; i < record.subRecords.length; i++) {
    if (searchDescriptionFilter(record.subRecords[i], filters)) {
      return true;
    }
  }
  return false;
};

export const filterSubRecords = (array, filters) => {
  array = cloneObject(array);
  return array.filter((record) => {
    if (subRecordsPropFilter(record, filters) && searchDescriptionFilter(record, filters)) {
      return true;
    } else {
      return null;
    }
  });
};

const subRecordsPropFilter = (record, filters) => {
  if (
    (filters.accountValue.indexOf(record.account) !== -1 ||
      filters.accountValue.indexOf('All') !== -1) &&
    (filters.classValue.indexOf(record.class) !== -1 || filters.classValue.indexOf('All') !== -1) &&
    (filters.categoryValue.indexOf(record.category) !== -1 ||
      filters.categoryValue.indexOf('All') !== -1) &&
    //(record.category === categoryValue || categoryValue === "All") &&
    (record.transactionType === filters.transactionType || filters.transactionType === 'All')
  ) {
    return true;
  }
  return false;
};

const checkFiltersForRec = (record, filters) => {
  if (filters.rec === 'All') {
    return true;
  } else if (filters.rec === 'Done') {
    return record.rec === 'Done' ? true : false;
  } else if (filters.rec === 'Unreconciled') {
    return record.rec !== 'Done' ? true : false;
  }
};

const checkFilterForAccount = (subRecords, filters) => {
  for (var i = 0; i < subRecords.length; i++) {
    if (
      filters.accountValue.indexOf(subRecords[i].account) !== -1 ||
      filters.accountValue.indexOf('All') !== -1
    ) {
      return true;
    }
  }
  return false;
};

const checkFilterForClass = (subRecords, filters) => {
  for (var i = 0; i < subRecords.length; i++) {
    if (
      filters.classValue.indexOf(subRecords[i].class) !== -1 ||
      filters.classValue.indexOf('All') !== -1
    ) {
      return true;
    }
  }
  return false;
};

const checkFilterForCategory = (subRecords, filters) => {
  for (var i = 0; i < subRecords.length; i++) {
    /* if (subRecords[i].category === categoryValue || categoryValue === "All") {
        return true;
      } */
    if (
      filters.categoryValue.indexOf(subRecords[i].category) !== -1 ||
      filters.categoryValue.indexOf('All') !== -1
    ) {
      return true;
    }
  }
  return false;
};

const checkFilterForTransactionType = (subRecords, filters) => {
  for (var i = 0; i < subRecords.length; i++) {
    if (
      subRecords[i].transactionType === filters.transactionType ||
      filters.transactionType === 'All'
    ) {
      return true;
    }
  }
  return false;
};

const ledgerPropFilter = (record, filters) => {
  if (
    //(record.account === accountValue || accountValue === "All") &&
    checkFiltersForRec(record, filters) &&
    checkFilterForAccount(record.subRecords, filters) &&
    checkFilterForClass(record.subRecords, filters) &&
    checkFilterForCategory(record.subRecords, filters) &&
    checkFilterForTransactionType(record.subRecords, filters)
  ) {
    return true;
  } else {
    return false;
  }
};

const searchDescriptionFilter = (record, filters) => {
  if (filters.searchValue === '') {
    return true;
  } else {
    if (filters.searchByValue === 'Description' || filters.searchByValue === 'Duplicates') {
      var sourceStr = record.description ? record.description.toLowerCase() : '';
      var searchStr = filters.searchValue.toLowerCase();
      if (sourceStr.search(searchStr) !== -1) {
        return true;
      }
    } else {
      return true;
    }
  }
  return false;
};

const searchAmountFilter = (record, filters, setupData) => {
  if (filters.searchValue === '') {
    return true;
  } else if (filters.searchByValue === 'Description' || filters.searchByValue === 'Duplicates') {
    return true;
  } else {
    var searchNumber = parseFloat(filters.searchValue);
    var sourceNumber;
    if (filters.searchByValue === 'Total') {
      sourceNumber = parseFloat(record.total);
    } else if (filters.searchByValue === setupData.taxCollection[0].name) {
      sourceNumber = parseFloat(record.tax1);
    } else if (filters.searchByValue === setupData.taxCollection[1].name) {
      sourceNumber = parseFloat(record.tax2);
    } else if (filters.searchByValue === 'Net') {
      sourceNumber = parseFloat(record.net);
    }

    if (filters.searchByComparatorsValue === 'Equals') {
      if (sourceNumber === searchNumber) {
        return true;
      } else {
        return false;
      }
    } else if (filters.searchByComparatorsValue === 'Greater than') {
      if (sourceNumber > searchNumber) {
        return true;
      } else {
        return false;
      }
    } else if (filters.searchByComparatorsValue === 'Less than') {
      if (sourceNumber < searchNumber) {
        return true;
      } else {
        return false;
      }
    }
  }
};

const dateFilter = (record, filters) => {
  const copyFilters = { ...filters };
  var recordDate = new Date(record.date);
  recordDate.setHours(0);
  recordDate.setMinutes(0);
  recordDate.setSeconds(0);
  recordDate.setMilliseconds(0);
  var filterDate1 = new Date(copyFilters.selectedDate1);
  var filterDate2 = new Date(copyFilters.selectedDate2);
  filterDate1.setHours(0);
  filterDate1.setMinutes(0);
  filterDate1.setSeconds(0);
  filterDate1.setMilliseconds(0);
  filterDate2.setHours(0);
  filterDate2.setMinutes(0);
  filterDate2.setSeconds(0);
  filterDate2.setMilliseconds(0);
  copyFilters.selectedDate1 = filterDate1.toDateString();
  copyFilters.selectedDate2 = filterDate2.toDateString();
  if (copyFilters.dateComparatorValue === 'All') {
    return true;
  } else if (copyFilters.dateComparatorValue === 'Between') {
    if (recordDate >= filterDate1 && recordDate <= filterDate2) {
      return true;
    } else {
      return false;
    }
  } else if (copyFilters.dateComparatorValue === 'On') {
    if (recordDate.getTime() === filterDate1.getTime()) {
      return true;
    } else {
      return false;
    }
  } else if (copyFilters.dateComparatorValue === 'Before') {
    if (recordDate.getTime() < filterDate1.getTime()) {
      return true;
    } else {
      return false;
    }
  } else if (copyFilters.dateComparatorValue === 'After') {
    if (recordDate.getTime() > filterDate1.getTime()) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const cloneObject = (object) => {
  let jObject = JSON.stringify(object);
  let newObject = JSON.parse(jObject);
  return newObject;
};

export const formatDate = (_date, setupData) => {
  if (_date === null || _date === undefined || _date === '') {
    return '';
  } else {
    var newDate = new Date(_date);
    var dateStr = '';
    if (setupData.dateFormat === 'dd/MM/yyyy') {
      dateStr =
        formatNumber(newDate.getDate()) +
        '/' +
        formatNumber(newDate.getMonth() + 1) +
        '/' +
        formatNumber(newDate.getFullYear());
    } else {
      dateStr =
        formatNumber(newDate.getMonth() + 1) +
        '/' +
        formatNumber(newDate.getDate()) +
        '/' +
        formatNumber(newDate.getFullYear());
    }
    return dateStr;
  }
};

export const getLongDate = (_date) => {
  var newDate = new Date(_date);
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  return newDate.getDate() + ' ' + monthNames[newDate.getMonth()] + ', ' + newDate.getFullYear();
};

const formatNumber = (nr) => {
  if (nr < 10) {
    return '0' + nr;
  } else {
    return nr;
  }
};

export const calculateTaxes = (subRecord, setupData, config, isNewRecord) => {
  const _subRecord = _.cloneDeep(subRecord);
  // console.log({ taxCollection: setupData.taxCollection, subRecord: _subRecord });

  let tax1Rate = parseFloat(setupData.taxCollection[0].rate);
  let tax2Rate = parseFloat(setupData.taxCollection[1].rate);
  let tax1Perc = tax1Rate / 100;

  if (!_subRecord.taxes[0].selected) {
    tax1Perc = 0;
  }
  let tax2Perc = tax2Rate / 100;
  if (!_subRecord.taxes[1].selected) {
    tax2Perc = 0;
  }
  let totalTax = tax1Perc + tax2Perc + 1;
  let net = _subRecord.total / totalTax;
  let tax1 = net * tax1Perc;
  let tax2 = net * tax2Perc;

  // console.log('tax1', tax1, 'tax2', tax2)
  // console.log('_subRecord tax1', _subRecord.taxes[0].value, '_subRecord tax2', _subRecord.taxes[1].value)

  // console.log({ isNewRecord });
  // if (isNewRecord) {
  _subRecord.taxes[0].value = tax1;
  _subRecord.taxes[1].value = tax2;


  // } else {
  //   if (_subRecord.taxes[0].value === tax1) {
  //     _subRecord.taxes[0].value = tax1;
  //   }
  //   if (_subRecord.taxes[1].value === tax2) {
  //     _subRecord.taxes[1].value = tax2;
  //   }
  // }

  // console.log({ subRecordTax0val: _subRecord.taxes[0].value });
  //? _subRecord.taxes[0].value =
  //?   _subRecord.taxes[0].value === net * tax1Perc ? tax1 : _subRecord.taxes[0].value;
  //*
  //*
  // console.log({ subRecordTax0val: _subRecord.taxes[0].value });
  // _subRecord.taxes[1].value =
  //   _subRecord.taxes[1].value === net * tax2Perc ? tax2 : _subRecord.taxes[1].value;
  //*
  // _subRecord.taxes[1].value = tax2;
  //*

  _subRecord.net = net - (_subRecord.taxes[0].adjustment + _subRecord.taxes[1].adjustment);
  // _subRecord.net = net;

  if (config.taxType === 'No Tax') {
    _subRecord.taxes[0].value = 0;
    _subRecord.taxes[1].value = 0;
    _subRecord.net = _subRecord.total;
  }

  return _subRecord;
};

export const calculateNet = (record) => {
  ///// income
  let totalIncome = 0;
  let tax1Income = 0;
  let tax2Income = 0;
  let netIncome = 0;

  ///// expense
  let totalExpense = 0;
  let tax1Expense = 0;
  let tax2Expense = 0;
  let netExpense = 0;

  ///// transfer in
  let totalTransferIn = 0;
  let tax1TransferIn = 0;
  let tax2TransferIn = 0;
  let netTransferIn = 0;

  //// transfer out
  let totalTransferOut = 0;
  let tax1TransferOut = 0;
  let tax2TransferOut = 0;
  let netTransferOut = 0;

  let multiTypes = false;
  let showExpense = false;
  let showIncome = false;
  let showTransferIn = false;
  let showTransferOut = false;

  for (var i = 0; i < record.subRecords.length; i++) {
    let subRecord = record.subRecords[i];
    if (
      subRecord.transactionType === 'Cost of Goods Sold' ||
      subRecord.transactionType === 'Expense'
    ) {
      totalExpense = totalExpense + parseFloat(subRecord.total);
      tax1Expense =
        tax1Expense + parseFloat(subRecord.taxes[0].value + subRecord.taxes[0].adjustment);
      tax2Expense =
        tax2Expense + parseFloat(subRecord.taxes[1].value + subRecord.taxes[1].adjustment);
      netExpense = netExpense + parseFloat(subRecord.net);
      showExpense = true;
      if (showIncome || showTransferIn || showTransferOut) {
        multiTypes = true;
      }
    } else if (subRecord.transactionType === 'Income') {
      totalIncome = totalIncome + parseFloat(subRecord.total);
      tax1Income =
        tax1Income + parseFloat(subRecord.taxes[0].value + subRecord.taxes[0].adjustment);
      tax2Income =
        tax2Income + parseFloat(subRecord.taxes[1].value + subRecord.taxes[1].adjustment);
      netIncome = netIncome + parseFloat(subRecord.net);
      showIncome = true;
      if (showExpense || showTransferIn || showTransferOut) {
        multiTypes = true;
      }
    } else if (subRecord.category === 'Transfer In(+)') {
      totalTransferIn = totalTransferIn + parseFloat(subRecord.total);
      tax1TransferIn =
        tax1TransferIn + parseFloat(subRecord.taxes[0].value + subRecord.taxes[0].adjustment);
      tax2TransferIn =
        tax2TransferIn + parseFloat(subRecord.taxes[1].value + subRecord.taxes[1].adjustment);
      netTransferIn = netTransferIn + parseFloat(subRecord.net);
      showTransferIn = true;
      if (showExpense || showIncome || showTransferOut) {
        multiTypes = true;
      }
    } else if (subRecord.category === 'Transfer Out(-)') {
      totalTransferOut = totalTransferOut + parseFloat(subRecord.total);
      tax1TransferOut =
        tax1TransferOut + parseFloat(subRecord.taxes[0].value + subRecord.taxes[0].adjustment);
      tax2TransferOut =
        tax2TransferOut + parseFloat(subRecord.taxes[1].value + subRecord.taxes[1].adjustment);
      netTransferOut = netTransferOut + parseFloat(subRecord.net);
      showTransferOut = true;
      if (showExpense || showIncome || showTransferIn) {
        multiTypes = true;
      }
    }
  }
  let netTax1 = tax1Income + tax1TransferIn - (tax1Expense + tax1TransferOut);
  let netTax2 = tax2Income + tax2TransferIn - (tax2Expense + tax2TransferOut);
  let grossIncome = netIncome + netTransferIn - (netExpense + netTransferOut);
  let grossIncomeBeforeTax = totalIncome + totalTransferIn - (totalExpense + totalTransferOut);
  return {
    income: [totalIncome, tax1Income, tax2Income, netIncome],
    expense: [totalExpense, tax1Expense, tax2Income, netExpense],
    transferIn: [totalTransferIn, tax1TransferIn, tax2TransferIn, netTransferIn],
    transferOut: [totalTransferOut, tax1TransferOut, tax2TransferOut, netTransferOut],
    grossIncome,
    grossIncomeBeforeTax,
    displayMode: [multiTypes, showIncome, showExpense, showTransferIn, showTransferOut],
    netTax1,
    netTax2
  };
};

////calculateCommissionRecords
export const calculateRecords1 = (record, setupData, config) => {
  let total = 0;
  let tax1 = 0;
  let tax2 = 0;
  let net = 0;
  const _record = _.cloneDeep(record);
  for (var i = 0; i < _record.subRecords.length; i++) {
    let subRecord = calculateTaxes(_record.subRecords[i], setupData, config);
    _record.subRecords[i] = subRecord;
    if (
      subRecord.transactionType === 'Cost of Goods Sold' ||
      subRecord.transactionType === 'Expense'
    ) {
      total = total - parseFloat(subRecord.total);
      tax1 = tax1 - parseFloat(subRecord.taxes[0].value + subRecord.taxes[0].adjustment);
      tax2 = tax2 - parseFloat(subRecord.taxes[1].value + subRecord.taxes[1].adjustment);
      net = net - parseFloat(subRecord.net);
    } else {
      total = total + parseFloat(subRecord.total);
      tax1 = tax1 + parseFloat(subRecord.taxes[0].value + subRecord.taxes[0].adjustment);
      tax2 = tax2 + parseFloat(subRecord.taxes[1].value + subRecord.taxes[1].adjustment);
      net = net + parseFloat(subRecord.net);
    }
  }
  _record.total = total;
  _record.tax1 = tax1;
  _record.tax2 = tax2;
  _record.net = net;
  return _record;
  //setNewRecord(record);
  //dispatch(updateRecord(record));
};

export const calculateRecords = (record, setupData, config, isNewRecord) => {
  let total = 0;
  let tax1 = 0;
  let tax2 = 0;
  let net = 0;

  const _record = _.cloneDeep(record);
  for (var i = 0; i < _record.subRecords.length; i++) {
    let subRecord = calculateTaxes(_record.subRecords[i], setupData, config, isNewRecord);
    _record.subRecords[i] = subRecord;
    total = total + parseFloat(subRecord.total);
    tax1 = tax1 + parseFloat(subRecord.taxes[0].value + subRecord.taxes[0].adjustment);
    tax2 = tax2 + parseFloat(subRecord.taxes[1].value + subRecord.taxes[1].adjustment);
    net = net + parseFloat(subRecord.net);
  }

  _record.total = total;
  _record.tax1 = tax1;
  _record.tax2 = tax2;
  _record.net = net;

  return _record;
  //setNewRecord(record);
  //dispatch(updateRecord(record));
};

export const updateFilterProperties = (filters, setupData) => {
  let classes = ['All'];
  for (var i = 0; i < setupData.classCollection.length; i++) {
    classes.push(setupData.classCollection[i].name);
  }
  filters.classValue = classes;
  ///////////////////////////

  let accounts = ['All'];
  for (var j = 0; j < setupData.accountCollection.length; j++) {
    accounts.push(setupData.accountCollection[j].name);
  }
  filters.accountValue = accounts;
  /////////////////////////
  let categories = ['All'];
  let merged = mergeCategories(filters.transactionType, setupData);
  for (var k = 0; k < merged.length; k++) {
    categories.push(merged[k].name);
  }
  filters.categoryValue = categories;
  return filters;
};

export const formatSubscriptionStatus = (status) => {
  let formattedStatus = '';
  if (status === 'active') {
    formattedStatus = 'Active';
  } else if (status === 'past_due') {
    formattedStatus = 'Past Due';
  } else if (status === 'unpaid') {
    formattedStatus = 'Unpaid';
  } else if (status === 'canceled') {
    formattedStatus = 'Canceled';
  } else if (status === 'incomplete') {
    formattedStatus = 'Incomplete';
  } else if (status === 'incomplete_expired') {
    formattedStatus = 'Incomplete Expired';
  } else if (status === 'trialing') {
    formattedStatus = 'Trialing';
  }
  return formattedStatus;
};

export const getSubscriptionMessage = (user) => {
  if (user.subscription_status.status === 'trialing') {
    var date = getLongDate(user.subscription_status.trial_end * 1000);
    return 'Your trial ends on: ' + date;
  } else if (user.subscription_status.status === 'past_due') {
    return 'Alert! Your invoice is Past Due';
  } else if (user.subscription_status.status === 'unpaid') {
    return 'Alert! Your invoice is Unpaid';
  } else if (user.customerSubcription?.pause_collection) {
    return 'Alert! Your subscription is Paused';
  } else if (user.subscription_status.status === 'canceled') {
    return 'Alert! Your subscription is Canceled';
  }
};

export const filterWorkspaceFiles = (fileList, activeClient) => {
  if (activeClient.client_id === 'own') {
    return fileList;
  } else {
    let filteredList = fileList.filter((fileObj) => {
      let included = false;
      for (var j = 0; j < activeClient.files.length; j++) {
        let file = activeClient.files[j];
        if (file.id === fileObj.file_id && (file.permissions.write || file.permissions.read)) {
          included = true;
        }
      }
      if (included) {
        return fileObj;
      } else {
        return null;
      }
    });
    return filteredList;
  }
};

export const checkFilePermission = (workspaceId, clients, activeFileId) => {
  if (workspaceId === 'own') {
    return true;
  } else {
    const client = clients.find((_client) => _client.client_id === workspaceId);
    const file = client.files.find((_file) => _file.id === activeFileId);
    if (file && file.permissions.write) {
      return true;
    } else {
      return false;
    }
  }
};
