import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface ISubscriptionStatus {
  status: string;
}

interface IclientData {
  email: string;
  subscriptionStatus: ISubscriptionStatus;
  subscriptionType: string;
}

interface IClient {
  client_id: string;
  client: IclientData;
}

interface ClientsState {
  clients: IClient[];
}

const initialState: ClientsState = {
  clients: []
};

const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    loadClients(state: ClientsState, action: PayloadAction<any>): void {
      state.clients = action.payload;
    }
  }
});

export const { loadClients } = slice.actions;

export default slice.reducer;
