import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface CurrentRecordsState {
  records: any[];
}

const initialState: CurrentRecordsState = {
  records: []
};

const slice = createSlice({
  name: 'currentRecords',
  initialState,
  reducers: {
    updateCurrentRecords(state: CurrentRecordsState, action: PayloadAction<any>): void {
      state.records = action.payload;
    }
  }
});

export const { updateCurrentRecords } = slice.actions;

export default slice.reducer;
