
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface ApiReducerState {
  data: any[];
}

const initialState: ApiReducerState = {
    data: []
  };

export const apiSlice = createSlice({
  name: 'apis',
  initialState,
  reducers: {
    addApiKeys: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
    addApiKeyReducer: (state, action: PayloadAction<any>) => {
      state.data.push(action.payload);
      // state.activeNoteId = action.payload.id;
    },
    deleteApiKeyReducer: (state, action: PayloadAction<string>) => {
      let filteredApiKeys = state.data.filter((item) => {
        if (item.sk !== action.payload) {
          return item;
        } else return null;
      });
      state.data = filteredApiKeys;
    }
  }
});

export const { addApiKeys, addApiKeyReducer, deleteApiKeyReducer } = apiSlice.actions;

export default apiSlice.reducer;
