import { createSlice } from "@reduxjs/toolkit";

interface AuxiliarState {
    isFreeSubCancelLocally: boolean
}

const initialState: AuxiliarState = { isFreeSubCancelLocally : false}

const slice = createSlice({
    name: 'auxiliar',
    initialState,
    reducers: {
        setIsFreeSubCancelLocally(state, action) {
            state.isFreeSubCancelLocally = action.payload
        }
    }
});

export const { setIsFreeSubCancelLocally } = slice.actions;

export default slice.reducer;