import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface Collaborator {
  id: string;
  client_id: string;
  collaborator_id: string;
  status: string;
  client: any;
  files: any;
}

interface CollaboratorsState {
  collaborators: Collaborator[];
}

const initialState: CollaboratorsState = {
  collaborators: []
};

const slice = createSlice({
  name: 'collaborators',
  initialState,
  reducers: {
    loadCollaborators(state: CollaboratorsState, action: PayloadAction<Collaborator[]>): void {
      state.collaborators = action.payload;
    },
    addCollaborator(state: CollaboratorsState, action: PayloadAction<Collaborator>): void {
      state.collaborators.push(action.payload);
    },
    updateCollaboratorFile(
      state: CollaboratorsState,
      action: PayloadAction<{ collab_id: string; files: any[] }>
    ): void {
      let colabIndex = state.collaborators.findIndex(
        (colab) => colab.id === action.payload.collab_id
      );
      let colab = state.collaborators[colabIndex];
      colab.files = action.payload.files;
      state.collaborators[colabIndex] = colab;
    },
    deleteCollaborator(state: CollaboratorsState, action: PayloadAction<string>): void {
      state.collaborators = state.collaborators.filter((colab) => colab.id !== action.payload);
    }
  }
});

export const { loadCollaborators, addCollaborator, updateCollaboratorFile, deleteCollaborator } =
  slice.actions;

export default slice.reducer;

// const collaborators = (state = [], action) => {
//   let colabs = [...state];
//   switch (action.type) {
//     case 'LOAD_COLLABORATORS':
//       return action.payload;
//     case 'ADD_COLLABORATOR':
//       colabs.push(action.payload);
//       return colabs;
//     case 'UPDATE_COLLABORATOR':
//       let colabIndex = colabs.findIndex((colab) => colab.id === action.payload.collab_id);
//       let colab = colabs[colabIndex];
//       colab.files = action.payload.files;
//       colabs[colabIndex] = colab;
//       return colabs;
//     case 'DELETE_COLLABORATOR':
//       return state.filter((colab) => colab.id !== action.payload);
//     default:
//       return state;
//   }
// };
// export default collaborators;
