import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface TempFiltersState {
  tempFilters: any;
}

const initialState: TempFiltersState = {
  tempFilters: null
};

const slice = createSlice({
  name: 'tempFilters',
  initialState,
  reducers: {
    updateTempFilters(state: TempFiltersState, action: PayloadAction<any>): void {
      state.tempFilters = action.payload;
    }
  }
});

export const { updateTempFilters } = slice.actions;

export default slice.reducer;

// const tempFilters = (state = null, action: any) => {
//   switch (action.type) {
//     case 'UPDATE':
//       return action.payload;
//     default:
//       return state;
//   }
// };
// export default tempFilters;
