import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface IsFilesLoadingState {
  isFilesLoading: boolean;
}

const initialState: IsFilesLoadingState = {
  isFilesLoading: true
};

const slice = createSlice({
  name: 'isFilesLoading',
  initialState,
  reducers: {
    setFilesLoading(state: IsFilesLoadingState, action: PayloadAction<boolean>): void {
      state.isFilesLoading = action.payload;
    }
  }
});

export const { setFilesLoading } = slice.actions;

export default slice.reducer;
