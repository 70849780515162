import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import C from '../constants';

interface ErrorState {
  error: any[];
}

const initialState: ErrorState = {
  error: []
};

const slice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    addError(state: ErrorState, action: PayloadAction<any>): void {
      state.error = [action.payload, ...state.error];
    },

    removeError(state: ErrorState, action: PayloadAction<any>): void {
      state.error = state.error.map((error, index) => index !== action.payload);
    },

    removeAllError(state: ErrorState): void {
      state.error = [];
    }
  }
});

export const { addError, removeError, removeAllError } = slice.actions;

export default slice.reducer;

// const errorReducer = (state = [], action: any) => {
//   switch (action.type) {
//     case C.ADD_ERROR:
//       return [action.payload, ...state];
//     case C.REMOVE_ERROR:
//       return state.map((error, index) => index !== action.payload);
//     case C.REMOVE_ALL_ERROR:
//       return [];
//     default:
//       return state;
//   }
// };
// export default errorReducer;
