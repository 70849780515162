import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface ImportOpenState {
  importOpen: boolean;
}

const initialState: ImportOpenState = {
  importOpen: false
};

const slice = createSlice({
  name: 'importOpenReducer',
  initialState,
  reducers: {
    setImportOpen(state: ImportOpenState, action: PayloadAction<boolean>): void {
      state.importOpen = action.payload;
    }
  }
});

export const { setImportOpen } = slice.actions;

export default slice.reducer;
