import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { initialUserConfig } from '../../data/initialUserConfig';

interface IWorkspaceData {
  clientSubscriptionStatus: string;
  workspaceEmail: string;
  clientSubscriptionType: string;
  workspaceId: string;
}

interface UserConfigState {
  selectedThemeId: string;
  activeFileId: string;
  workspace: IWorkspaceData;
}

const initialState: UserConfigState = { ...initialUserConfig };

const slice = createSlice({
  name: 'userConfig',
  initialState,
  reducers: {
    updateUserConfig(state: UserConfigState, action: PayloadAction<any>): void {
      const userConfigObj = action.payload;
      const copyUserConfigObj = { ...userConfigObj };

      if (!copyUserConfigObj.hasOwnProperty('activeFileId')) {
        copyUserConfigObj.activeFileId = '';
      }

      if (!copyUserConfigObj.hasOwnProperty('workspace')) {
        copyUserConfigObj.workspace = {
          workspaceId: 'own',
          workspaceEmail: '',
          clientSubscriptionType: 'expanded',
          clientSubscriptionStatus: 'active'
        };
      }
      state.selectedThemeId = copyUserConfigObj.selectedThemeId;
      state.activeFileId = copyUserConfigObj.activeFileId;
      state.workspace = copyUserConfigObj.workspace;
    }
  }
});

export const { updateUserConfig } = slice.actions;

export default slice.reducer;
