// import { ledgerFile } from "data/recordsData";
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface LedgerFileState {
  ledgerFiles: LedgerFile[];
}

interface LedgerFile {
  id?: string;
}

interface IPayloadUpdateCollapse {
  selectedRecords: any;
  collapseIn: any;
}

const initialState: LedgerFileState = {
  ledgerFiles: []
};

export const ledgerFileSlice = createSlice({
  name: 'ledgerFileReducer',
  initialState,
  reducers: {
    setRecords: (state, action: PayloadAction<LedgerFile[]>) => {
      state.ledgerFiles = action.payload;
    },
    addRecords: (state, action: PayloadAction<LedgerFile[]>) => {
      let ledgerFiles = [...state.ledgerFiles];
      for (var i = 0; i < action.payload.length; i++) {
        ledgerFiles.push(action.payload[i]);
      }
      state.ledgerFiles = ledgerFiles;
    },
    updateRecords: (state, action: PayloadAction<LedgerFile[]>) => {
      state.ledgerFiles = state.ledgerFiles.map((ledgerFile) => {
        let updatedLedgerFile = ledgerFile;
        for (let i = 0; i < action.payload.length; i++) {
          if (action.payload[i].id === ledgerFile.id) {
            updatedLedgerFile = action.payload[i];
            break;
          }
        }
        return updatedLedgerFile;
      });
    },
    deleteRecords: (state, action: PayloadAction<LedgerFile[]>) => {
      state.ledgerFiles = state.ledgerFiles.filter((ledgerFile) => {
        let included = false;
        for (let i = 0; i < action.payload.length; i++) {
          if (action.payload[i] === ledgerFile.id) {
            included = true;
          }
        }
        if (included) {
          return null;
        } else {
          return ledgerFile;
        }
      });
    },
    updateCollapse: (state, action: PayloadAction<IPayloadUpdateCollapse>) => {
      state.ledgerFiles = state.ledgerFiles.map((ledgerFile) => {
        let included = false;
        for (let i = 0; i < action.payload.selectedRecords.length; i++) {
          if (action.payload.selectedRecords[i].id === ledgerFile.id) {
            included = true;
          }
        }
        if (included) {
          return { ...ledgerFile, collapseIn: action.payload.collapseIn };
        } else {
          return ledgerFile;
        }
      });
    }
  }
});

export const { setRecords, addRecords, updateRecords, deleteRecords, updateCollapse } =
  ledgerFileSlice.actions;

export default ledgerFileSlice.reducer;
