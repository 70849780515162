import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface ShowBillingState {
  showBilling: boolean;
}

const initialState: ShowBillingState = {
  showBilling: false
};

const slice = createSlice({
  name: 'showBilling',
  initialState,
  reducers: {
    setShowBilling(state: ShowBillingState, action: PayloadAction<boolean>): void {
      state.showBilling = action.payload;
    }
  }
});

export const { setShowBilling } = slice.actions;

export default slice.reducer;
